import React, { useState, createRef } from 'react'
import Recaptcha from 'react-google-recaptcha'

const SITE_KEY = process.env.SITE_RECAPTCHA_KEY
if (typeof SITE_KEY === 'undefined') {
  throw new Error(`
    Oops! Your env var SITE_KEY is undefined.
    Try setting it up in your Netlify build environment variables.
  `)
}

// Encoder Function
function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default function Form() {
    // Initialize State and refs
    const [notification, setNotification] = useState('')
    const [input, setInput] = useState({})
    const recaptchaRef = createRef()

    // Form Input Hook
    const handleChange = (e) => {
      setInput({ ...input, [e.target.name]: e.target.value })
    }

    // Submit handler function
    const handleSubmit = async (e) => {
      e.preventDefault()
      const form = e.target
      const recaptchaValue = recaptchaRef.current.getValue()

      // Send email with token
      fetch('/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: encode({ 
          'form-name': form.getAttribute('name'), 
          'g-recaptcha-response': recaptchaValue,
          ...input
        })
      })
      .then(() =>{
        setNotification('Your message was sent successfully.')
      })
      .catch(err => {
        setNotification('There was a problem sending your message.')
      })
    }

    return (
      <form 
        id="contact-form" 
        name="contact-form" 
        method="post"
        data-netlify="true"
        data-netlify-hotpot="bot-field"
        data-netlify-recaptcha="true"
        onSubmit={handleSubmit}
      >
        <div className="fields">
        <div className="field" hidden>
          <input type="hidden" name="form-name" value="contact-form" />
        </div>
        <div className="field" hidden>
          <label>Don't fill this out <input name="bot-field" onChange={handleChange} /></label>
        </div>
          <div className="field">
            <label htmlFor="name">Name</label>
            <input 
              type="text" 
              name="name" 
              id="name" 
              onChange={handleChange} 
              required 
            />
          </div>
          <div className="field">
            <label htmlFor="email">Email</label>
            <input 
              type="email" 
              name="email" 
              id="email" 
              onChange={handleChange}
              required 
            />
          </div>
          <div className="field">
            <label htmlFor="message">Message</label>
            <textarea 
              name="message" 
              id="message" 
              rows="4" 
              onChange={handleChange}
              required
            >
            </textarea>
          </div>
          <div className="field">
            <Recaptcha 
              ref={recaptchaRef} 
              sitekey={SITE_KEY} 
              theme="dark"
              size="normal"
            />
          </div>
        </div>
        <ul className="actions">
          <li>
            <input type="submit" value="Send Message" />
          </li>
        </ul>
        {notification && <span id="notification">{notification}</span>}
      </form>
    )
}