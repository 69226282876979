module.exports = {
  siteTitle: 'Xaviermachina IT Solutions', // <title>
  manifestName: 'SolidState',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-solidstate/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Xaviermachina IT Solutions',
  subHeading: '',
  // social
  socialLinks: [
    {
      icon: 'fa-facebook',
      name: 'Xaviermachina @ Facebook',
      url: 'https://facebook.com/xaviermachinadevelopment',
    },
    {
      icon: 'fa-instagram',
      name: 'Xaviermachina @ Instagram',
      url: 'https://instagram.com/xaviermachina',
    },
    {
      icon: 'fa-envelope-o',
      name: 'software@xaviermachina.com',
      url: 'mailto:software@xaviermachina.com',
    },
  ],
};
